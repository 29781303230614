import { useEffect, useState, useCallback } from "react";
import { globalHistory, navigate } from "@reach/router";
import queryString from "query-string";
import mapValues from "lodash/mapValues";

function decode(obj) {
  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined
  };
  return mapValues(obj, val => {
    if (val in keywords) {
      return keywords[val];
    }
    if (!isNaN(parseInt(val, 10))) {
      return parseInt(val, 10);
    }
    return val;
  });
}

export default function useSearchQuery(name) {
  // eslint-disable-next-line no-restricted-globals
  const rawParams = queryString.parse(window.location.search);
  const urlParams = decode(rawParams);
  const [allParams, setParamsState] = useState(urlParams);

  const setParams = useCallback(newParams => {
    const query = queryString.stringify(newParams);
    navigate(`?${query}`);
  });
  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ location }) => {
      const newParams = decode(queryString.parse(location.search));
      setParamsState(newParams);
    });
    return unsubscribe;
  });
  return [allParams[name], setParams];
}
