import { createMuiTheme } from "@material-ui/core/styles";

const primary = {
  light: "#673e79",
  main: "#410E58",
  dark: "#2d093d"
  // contrastText: string;
};

export const theme = createMuiTheme({
  palette: {
    primary
  },
  typography: {
    fontFamily: "Spinnaker",
    useNextVariants: true
  }
});

export default theme;
