import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import { makeStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
// import deburr from "lodash/deburr";
import suggest from "./suggest";
import { defaultFormat } from "./date-format";

const getSuggestions = query => {
  const sug = suggest(query);
  console.log("suggestions", sug);
  return sug;
};

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    selectedItem && selectedItem.formatted === suggestion.formatted;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.formatted}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      <ListItemText
        primary={suggestion.formatted}
        secondary={defaultFormat(suggestion.date)}
      />
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // height: 250
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: 8, //theme.spacing.unit,
    left: 0,
    right: 0
  },
  inputRoot: {
    flexWrap: "wrap"
  },
  inputInput: {
    width: "auto",
    flexGrow: 1
  },
  divider: {
    height: 16 //theme.spacing.unit * 2
  }
}));

function suggestionToString(sug) {
  return sug && `${sug.formatted} (${defaultFormat(sug.date)})`;
}

function IntegrationDownshift(props) {
  const classes = useStyles();
  const { name, onChange, value, ...restTextField } = props;
  return (
    <div className={classes.root}>
      <Downshift itemToString={suggestionToString} onChange={onChange}>
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          openMenu,
          setState
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              placeholder: "Try: tomorrow, 3pm, 4 days, dec 15",
              InputLabelProps: {
                shrink: true
              },
              InputProps: getInputProps({
                name,
                onFocus: () => setState({ inputValue: "" }, openMenu)
              }),
              ...restTextField
            })}
            <div {...getMenuProps()}>
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {getSuggestions(inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    </div>
  );
}

export default IntegrationDownshift;
