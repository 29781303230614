import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  & + & {
    margin-top: 16px;
  }
`;

const Content = styled.div`
  margin-top: 4px;
`;

export function InfoField({ label, children, ...rest }) {
  return (
    <Container {...rest}>
      <InputLabel shrink>{label}</InputLabel>
      {children && <Content>{children}</Content>}
    </Container>
  );
}

export default InfoField;
