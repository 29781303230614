// import { GET_CONTACT_QUERY } from "../contact-card";
import gql from "graphql-tag";
import { useMutation } from "react-apollo-hooks";
import { useCallback } from "react";
import { GET_OVERVIEW_SECTION_QUERY } from "../overview/overview-card";

const SCHEDULE_TASK_MUTATION = gql`
  mutation scheduleTask($contactId: ID!, $task: TaskInput!) {
    scheduleTask(contactId: $contactId, task: $task) {
      type
      date
      subject
      description
    }
  }
`;

export default function useScheduleTask(contactId) {
  const addTask = useMutation(SCHEDULE_TASK_MUTATION, {
    update: (proxy, mutationResult) => {
      const task = mutationResult.data.scheduleTask;
      const queryRes = proxy.readQuery({
        query: GET_OVERVIEW_SECTION_QUERY,
        variables: {
          contactId
        }
      });
      proxy.writeQuery({
        query: GET_OVERVIEW_SECTION_QUERY,
        variables: {
          contactId
        },
        data: {
          contactById: {
            ...queryRes.contactById,
            tasks: [task, ...queryRes.contactById.tasks]
          }
        }
      });
    }
  });

  const action = useCallback(
    task =>
      addTask({
        variables: { contactId: contactId, task }
      }),
    [contactId]
  );
  return action;
}
