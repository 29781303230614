import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import ReactDOM from "react-dom";
import CardContent from "@material-ui/core/CardContent";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  DialogActions
} from "@material-ui/core";
import map from "lodash/map";

const recommendTemplate = link => name => `Hi ${name},
just read a great article and thought it might interest you:${link}`;

const templates = {
  crypto: {
    name: "crypto",
    label: "Crypto",
    text: recommendTemplate(
      "https://medium.com/@arjunblj/crypto-theses-for-2019-dd20cb7f9895"
    )
  },
  taxes: {
    name: "taxes",
    label: "Taxes",
    text: recommendTemplate(
      "https://medium.com/the-jist/was-eu-tax-evasion-regulation-the-reason-for-the-brexit-referendum-980ba88a8077"
    )
  }
};

function TemplateSelect({ onChange, value }) {
  function handleChange(e) {
    onChange(e.target.value);
  }
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabelRef = React.useRef(null);

  React.useEffect(() => {
    setLabelWidth(ReactDOM.findDOMNode(inputLabelRef.current).offsetWidth);
  }, []);
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel ref={inputLabelRef} htmlFor="template">
        Recommendation
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="template"
            id="template"
          />
        }
      >
        {map(templates, t => (
          <MenuItem key={t.name} value={t.name}>
            {t.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

/*
const useStyles = makeStyles(theme => ({
  whatsapp: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  telegram: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700]
    }
  }
}));
*/

const GET_CONTACT_OUTREACH_QUERY = gql`
  query contactOutreachInfoById($contactId: ID) {
    contactById(contactId: $contactId) {
      firstName
      lastName
      email
    }
  }
`;

export default function ContactMessage({ contactId, navigate }) {
  const [templateKey, setTemplateKey] = useState("none");
  const template = templates[templateKey] || { text: name => `Hi ${name}, ` };
  // const classes = useStyles();

  const { data, error } = useQuery(GET_CONTACT_OUTREACH_QUERY, {
    variables: { contactId: contactId }
  });
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const { firstName /* lastName, email */ } = data.contactById;

  return (
    <>
      <CardContent>
        <TemplateSelect onChange={setTemplateKey} value={templateKey} />
        <TextField
          label="Text"
          multiline
          rowsMax="8"
          rows="4"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          value={template.text(firstName)}
        />
      </CardContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("../../next")}
        >
          Send SMS
        </Button>
        {/* <Button
          variant="contained"
          className={classes.whatsapp}
          onClick={() => navigate("../../next")}
        >
          Send via WhatsApp
        </Button>
        <Button
          variant="contained"
          className={classes.telegram}
          onClick={() => navigate("../../next")}
        >
          Send via Telegram
        </Button> */}
      </DialogActions>
    </>
  );
}
