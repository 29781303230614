import React from "react";
import posed, { PoseGroup } from "react-pose";
import { Router, Match } from "@reach/router";

/*
const Slide = {
  preEnter: {
    x: "100vw"
  },
  enter: {
    x: "0%",
    // opacity: 1
    // delay: 300,
    transition: {
      duration: 700,
      ease: "linear"
    }
  },
  exit: {
    x: "-100vw",
    transition: {
      ease: "linear",
      duration: 700
    }
    // opacity: 0
  }
};
*/

const Fade = {
  enter: { opacity: 1, delay: 700, beforeChildren: 300 },
  exit: { opacity: 0, duration: 300 }
};
// https://codesandbox.io/s/l48wl5jno9
const RouteContainer = posed.div(Fade);

export const SlideRouter = ({ children, location }) => {
  return (
    <Match path="/contacts/:contactId/:main/*">
      {({ match }) => {
        const matchKey = match ? match.main : "overview";
        return (
          <PoseGroup preEnterPose="preEnter">
            <RouteContainer key={matchKey}>
              <Router location={location}>{children}</Router>
            </RouteContainer>
          </PoseGroup>
        );
      }}
    </Match>
  );
};

// const SlideRouter = Router;
export default SlideRouter;
