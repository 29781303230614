let contacts = [
  /*{
    uuid: "1000-1000-1000",
    firstName: "Torben",
    lastName: "Hein",
    phone: "030 88888888",
    email: "torben.hein@deliveryhero.com",
    role: "Salesforce Product Owner",
    image:
      "https://media.licdn.com/dms/image/C4E03AQHCoN3dxEqspg/profile-displayphoto-shrink_800_800/0?e=1555545600&v=beta&t=ighxMe1pBsHFyqJInTRoDZr90d2dUpgg2vwj0D78oPY",
    interests: ["Salesforce", "Kids", "Processes"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-05T09:45:00Z",
        subject: "Invite: Laserfocus @ Delivery Hero"
      },
      {
        type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-12T13:00:00Z",
        subject: "Demo Laserfocus.io"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-31T17:01:00Z",
        subject: "Re: Intro Daniel Dinh Laserfocus"
      }
    ],
    tasks: [
      {
       type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-28T11:00:00Z",
        subject: "Demo Laserfocus.io für Delivery Hero",
        description: "Show the product"
      }
    ],
    company: {
      name: "Delivery Hero SE",
      url: "www.deliveryhero.com",
      address: {
        street: "Oranienburgerstr.70",
        city: "Berlin",
        zip: "10117"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Oranienburgerstr.70",
          city: "Berlin",
          zip: "10117"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus for DH",
            closeDate: "February 2019",
            value: 600,
            type: "New Business"
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Salesforce",
        reportsTo: "-"
      }
    }
  },*/
  {
    uuid: "1000-1000-1000",
    firstName: "Carla",
    lastName: "Garcia",
    phone: "030 88888888",
    email: "carla.garcia@morressier.com",
    role: "Head of Sales & Operations",
    image:
      "https://media.licdn.com/dms/image/C4D03AQHtuJENw9-Wig/profile-displayphoto-shrink_800_800/0?e=1557964800&v=beta&t=pInI96oKN3cDW4EJKNwaSquRnyyJ-nZA4rKt2iwdVRk",
    interests: ["Salesforce", "Femspire", "Processes"],
    activities: [
      {
        type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-03-11T14:00:00Z",
        subject: "Meeting with Carla at Morressier"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-03-05T09:45:00Z",
        subject: "Meeting with Carla at Morressier"
      },
      {
        type: "MESSAGE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-13T17:01:00Z",
        subject: "LINKEDIN"
      }
    ],
    tasks: [
      {
       type: "MESSAGE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-28T11:00:00Z",
        subject: "LINKEDIN",
        description: "Show the product"
      }
    ],
    company: {
      name: "Moressier",
      url: "www.moressier.com",
      address: {
        street: "Zossener Str.55-58",
        city: "Berlin",
        zip: "10961"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Zossener Str.55-58",
          city: "Berlin",
          zip: "10961"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus for Moressier",
            closeDate: "March 2019",
            value: 600,
            type: "New Business"
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Management",
        reportsTo: "-"
      }
    }
  },
  {
    uuid: "1000-1000-2000",
    firstName: "Ali",
    lastName: "El Ali",
    phone: "030 88888888",
    email: "Ali@lieferheld.de",
    role: "Head of Account Management",
    image:
      "https://media.licdn.com/dms/image/C4D03AQH3-ynHkm9GpA/profile-displayphoto-shrink_800_800/0?e=1555545600&v=beta&t=RU3OjEeXqusbidWEAzoN_-Y8aIG9KDLP9L_B7v5YKQw",
    interests: ["Sales", "FIFA 12", "Management"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-05T09:45:00Z",
        subject: "Invite: Laserfocus @ Delivery Hero"
      },
      {
        type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-12T13:00:00Z",
        subject: "Laserfocus @ Delivery Hero"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-31T17:01:00Z",
        subject: "Re: Intro Daniel Dinh Laserfocus"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-28T18:01:00Z",
        subject: "Intro Daniel Dinh Laserfocus"
      }
    ],
    tasks: [
      {
        type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-28T11:00:00Z",
        subject: "Demo Laserfocus.io für Delivery Hero",
        description: "Show the product"
      }
    ],
    company: {
      name: "Delivery Hero SE",
      url: "www.deliveryhero.com",
      address: {
        street: "Oranienburgerstr.70",
        city: "Berlin",
        zip: "10117"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Oranienburgerstr.70",
          city: "Berlin",
          zip: "10117"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus for DH",
            closeDate: "February 2019",
            value: 600,
            type: "New Business"
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Salesforce",
        reportsTo: "-"
      }
    }
  },
  {
    uuid: "1000-1000-3000",
    firstName: "Simon",
    lastName: "Harske",
    phone: "String",
    email: "simon.harske@lemoncat.de",
    role: "Entrepreneur in Residence",
    image:
      "https://media.licdn.com/dms/image/C4D03AQE8eWnt_-Wfhg/profile-displayphoto-shrink_800_800/0?e=1554940800&v=beta&t=FLHtcgzEgVzqH7gnBEcpQfXpICmiE0-f0bA-BQqShS8",
    interests: ["Strategy", "Consulting", "Ventures"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-05T09:45:00Z",
        subject: "Philipp für Sales"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-04T17:43:00Z",
        subject: "Demo Laserfocus.io für LEMONCAT"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-04T18:01:00Z",
        subject:
          "Accepted: Einladung: Daniel <> Simon - Fr 8. Feb. 2019 11AM - 12PM (MEZ) "
      }
    ],
    tasks: [
      {
        type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-02-08T11:00:00Z",
        subject: "Demo Laserfocus.io",
        description: ""
      }
    ],
    company: {
      name: "LEMONCAT GmbH",
      url: "www.lemoncat.de",
      address: {
        street: "Dircksenstr.47",
        city: "Berlin",
        zip: "10178"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Dircksenstr.47",
          city: "Berlin",
          zip: "10178"
        },
        tax_number: "123456789",
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus for Lemoncat",
            closeDate: "February 2019",
            value: 600,
            type: "New Business"
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Management",
        reportsTo: "Doreen Huber"
      }
    }
  },
  {
    uuid: "1000-1000-4000",
    firstName: "Gavin",
    lastName: "Belson",
    phone: "String",
    email: "Gavin.B@hooli.com",
    role: "CEO",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxDeNmi4Nbs8ZfS6hN4CDh0bXtB7ij-lYR9OoplnzbMex40d4i",
    interests: ["Hooli", "Startups"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-25T12:00:00Z",
        subject: "Send Proposal"
      },
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-21T12:00:00Z",
        subject: "Solution for your Box Team"
      }
    ],
    tasks: [
      {
        type: "PHONE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-25T12:00:00Z",
        subject: "Close the Deal",
        description: ""
      }
    ],
    company: {
      name: "Hooli",
      url: "www.hooli.xyz",
      address: {
        street: "Stuff st.",
        city: "Berlin",
        zip: "23456789"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Stuff st.",
          city: "Berlin",
          zip: "23456789"
        },
        tax_number: "",
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "Laserfocus 3 year contract ",
            closeDate: "Feb 2019",
            value: 50000,
            type: "New Business"
          },
          {
            name: "Laserfocus addon",
            closeDate: "March 2019",
            value: 20000,
            type: "Existing Business"
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Managemennt",
        reportsTo: "-"
      }
    }
  },
  {
    uuid: "1000-1000-5000",
    firstName: "Monica",
    lastName: "Hall",
    phone: "String",
    email: "Monica@breamhall.com",
    role: "Partner",
    image: "https://breamhall.com/images/Monica@2x.jpg",
    interests: ["Investments", "Smoking"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-21T12:00:00Z",
        subject: "Send Pitchdeck"
      }
    ],
    tasks: [
      {
        type: "PHONE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-25T12:00:00Z",
        subject: "Talk about pitchdeck",
        description: ""
      }
    ],
    company: {
      name: "Breamhall",
      url: "www.breamhall.com",
      address: {
        street: "Stuff st.",
        city: "Berlin",
        zip: "23456789"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Stuff st.",
          city: "Berlin",
          zip: "23456789"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "Prototype LaserFocus",
            closeDate: "July 2019",
            value: 50000,
            type: "New Business"
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Finance",
        reportsTo: "Laurie Bream"
      }
    }
  },
  {
    uuid: "1000-1000-6000",
    firstName: "Donald",
    lastName: "Dunn",
    phone: "String",
    email: "Jared@piedpiper.com",
    role: "COO",
    image:
      "http://www.piedpiper.com/app/themes/pied-piper/dist/images/photo-jared.png",
    interests: ["Basements", "SWOT"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-25T12:00:00Z",
        subject: "Send Proposal"
      }
    ],
    tasks: [
      {
        type: "PHONE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-25T12:00:00Z",
        subject: "Call back",
        description: ""
      }
    ],
    company: {
      name: "Pied Piper",
      url: "www.piedpiper.com",
      address: {
        street: "Stuff st.",
        city: "Berlin",
        zip: "23456789"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Stuff st.",
          city: "Berlin",
          zip: "23456789"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "Prototype LaserFocus",
            closeDate: "March 2019",
            value: 5000
          },
          {
            name: "Upsell LaserFocus addon",
            closeDate: "September 2019",
            value: 20000
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Marketing",
        reportsTo: "God"
      }
    }
  },
  {
    uuid: "1000-1000-7000",
    firstName: "Russ",
    lastName: "Hannemann",
    phone: "String",
    email: "threekommas@gmail.com",
    role: "Founder",
    image:
      "https://vignette.wikia.nocookie.net/silicon-valley/images/7/7d/Russ_Hanneman.jpg/revision/latest?cb=20160903214702",
    interests: ["Sportscars", "Tequila"],
    activities: [
      {
        type: "MEETING", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-21T12:00:00Z",
        subject: "Talked about Investing"
      }
    ],
    tasks: [
      {
        type: "PHONE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-03-25T12:00:00Z",
        subject: "Wanted to talk",
        description: ""
      }
    ],
    company: {
      name: "RadioOnTheInternet",
      url: "www.radio.com",
      address: {
        street: "Stuff st.",
        city: "Berlin",
        zip: "23456789"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Stuff st.",
          city: "Berlin",
          zip: "23456789"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus Deal",
            closeDate: "March 2019",
            value: 5000
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Management",
        reportsTo: "-"
      }
    }
  },
  {
    uuid: "1000-1000-8000",
    firstName: "Laurie",
    lastName: "Bream",
    phone: "String",
    email: "Bream@raviga.com",
    role: "Lead Partner",
    image:
      "https://hbo.co.uk/uploads/images/silicon-valley/cast/laurie-bream.jpg",
    interests: ["AI", "Portfoliomanagement", "Robotics"],
    activities: [
      {
        type: "MESSAGE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-04T12:00:00Z",
        subject: "Let's meet for Coffee"
      }
    ],
    tasks: [
      {
        type: "PHONE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2019-01-15T12:00:00Z",
        subject: "Call back",
        description: ""
      }
    ],
    company: {
      name: "Raviga Capital",
      url: "www.raviga.com",
      address: {
        street: "Stuff st.",
        city: "Berlin",
        zip: "23456789"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Stuff st.",
          city: "Berlin",
          zip: "23456789"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus for Portfolio companies",
            closeDate: "July 2019",
            value: 500000,
            type: "New Business"
          }
        ]
      }
    }
  },
  {
    uuid: "1000-1000-9000",
    firstName: "Richard",
    lastName: "Hendricks",
    phone: "String",
    email: "Richard@piedpiper.com",
    role: "CEO, Founder",
    image:
      "https://s3.r29static.com//bin/entry/238/720x864,80/1927024/image.jpg",
    interests: ["Internet", "Decentralization"],
    activities: [
      {
        type: "EMAIL", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-02-25T12:00:00Z",
        subject: "Talk about the new Internet"
      }
    ],
    tasks: [
      {
        type: "PHONE", // can be one of MESSAGE | MEETING | EMAIL | PHONE
        date: "2015-02-25T12:00:00Z",
        subject: "See how the status is",
        description: ""
      }
    ],
    company: {
      name: "Pied Piper",
      url: "www.piedpiper.com",
      address: {
        street: "Stuff st.",
        city: "Berlin",
        zip: "23456789"
      }
    },
    connections: {
      sf_account: {
        address: {
          street: "Stuff st.",
          city: "Berlin",
          zip: "23456789"
        },
        description: "SF Account Description",
        type: "Prospect",
        opportunities: [
          {
            name: "LaserFocus on the new internet",
            closeDate: "July 2019",
            value: 50000
          }
        ]
      },
      sf_contact: {
        phone: "0173 27234233",
        email: "Email Address",
        department: "Management",
        reportsTo: "-"
      }
    }
  }
];

export default contacts;
