import _contacts from "./data";

// const taskTypes = [MEET, MAIL, CALL, MESSAGE];

let contacts = _contacts;

const mocks = {
  Query: (queryOptions, variables) => ({
    contacts: () => contacts,
    contactById: (root, params) => {
      !variables.contactId &&
        console.warn("ContactId is undefined:", variables.contactId);
      const withId = contacts.find(c => c.uuid === variables.contactId);
      return withId || contacts[0];
    }
  }),
  Mutation: () => ({
    addInterestToContact: (_, input) => {
      let target;
      contacts = contacts.map(c => {
        if (c.uuid === input.contactId) {
          c.interests = [...c.interests, ...input.interests];
          target = c;
        }
        return c;
      });
      return {
        ...target
      };
    },
    scheduleTask: (_, input) => {
      const { contactId, task } = input;
      contacts = contacts.map(c => {
        if (c.uuid === contactId) {
          c.tasks = [{ ...task, description: "" }, ...c.tasks];
        }
        return c;
      });
      return task;
    }
  })
  // Contact: () => ({
  //   uuid: () => faker.random.uuid(),
  //   firstName: () => faker.name.firstName(),
  //   lastName: () => faker.name.lastName(),
  //   phone: () => faker.phone.phoneNumber(),
  //   email: () => faker.internet.email(),
  //   role: () => faker.name.jobTitle(),
  //   image: () => faker.image.avatar(),
  //   tasks: () => new MockList(1),
  //   activities: () => new MockList(6),
  //   interests: () =>
  //     new MockList([1, 3], () => faker.random.arrayElement(interests))
  // }),
  // Task: () => {
  //   return {
  //     ...faker.random.arrayElement(tasks),
  //     date: () => faker.date.recent(5)
  //   };
  // },
  // Company: () => ({
  //   name: () => faker.company.companyName(),
  //   url: () => faker.internet.url()
  // }),
  // SF_CONTACT: () => ({
  //   department: () => faker.commerce.department(),
  //   reportsTo: () => faker.name.firstName() + " " + faker.name.lastName()
  // }),
  // SF_ACCOUNT: () => ({
  //   description: () => "Salesforce Account Description",
  //   type: () => "Prospect"
  // }),
  // SF_OPPORTUNITY: () => ({
  //   value: () => 30000,
  //   closeDate: () => faker.date.month()
  // }),
  // Address: () => ({
  //   street: faker.address.streetAddress(),
  //   zip: faker.address.zipCode(),
  //   city: faker.address.city()
  // }),
  // Activity: () => ({
  //   date: root => {
  //     console.log("DATEROOT");
  //     faker.date.recent(80);
  //   }
  // })
};

export default mocks;
