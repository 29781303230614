import {
  setHours,
  setMinutes,
  setSeconds,
  setMilliseconds,
  format
} from "date-fns";
import { SOMEDAY } from "./";

const resetHours = date =>
  setMilliseconds(setSeconds(setMinutes(setHours(date, 0), 0), 0), 0);

/*
 - 8:00 AM, 1:32 PM (if today)
 - JUN 6 (US), 6 JUN (non-US) (if this year)
 - M/D/YYYY (US), D/M/YYYY (non-US) (if not this year)
 */
export function defaultFormat(date) {
  if (date.getTime() === SOMEDAY) {
    return "Someday";
  }

  // NOTE(ibash) DateFormat.default is used in ThreadListItem. We do not use
  // the sugar methods for comparison (of whether the date is today vs this
  // year) because the sugar methods are inefficient. This may seem like we are
  // splitting hairs, but we measured the performance difference and it makes
  // ~25ms difference when loading a list of threads.
  const now = new Date();
  const today = resetHours(now);
  const rounded = resetHours(date);

  if (rounded.getTime() === today.getTime()) {
    return formatTime(date);
  } else if (rounded.getFullYear() === today.getFullYear()) {
    return formatDate(date);
  } else if (navigator.language === "en-US") {
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  } else {
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }
}

function formatDate(d) {
  const month = format(d, "MMM");
  const date = d.getDate();

  if (navigator.language === "en-US") {
    return month + " " + date;
  } else {
    return date + " " + month;
  }
}

function formatTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  // the hour '0' should be '12'
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return hours + ":" + minutes + " " + ampm;
}
