import React from "react";
import Autocomplete from "./autocomplete";

export const AutocompleteWrapper = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  return (
    <Autocomplete {...rest} name={name} onChange={onChange} value={value} />
  );
};

export default AutocompleteWrapper;
