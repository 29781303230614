import React from "react";
import useKey from "use-key-hook";
import { navigate } from "@reach/router";
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";

const ue = 219;

export function MeetingReminder({ children, enqueueSnackbar }) {
  useKey(
    pressedKey => {
      switch (pressedKey) {
        case ue:
          return enqueueSnackbar("Call with Monica", {
            variant: "default",
            action: (
              <Button
                color="secondary"
                size="small"
                onClick={() =>
                  navigate("/contacts/1000-1000-3000/contact/meeting")
                }
              >
                Take Notes
              </Button>
            ),
            anchorOrigin: {
              vertical: "top",
              horizontal: "left"
            }
          });

        default:
          console.log("Pressed Key not defined -.-", pressedKey);
      }
    },
    {
      detectKeys: [ue]
    }
  );
  return children || null;
}

export default withSnackbar(MeetingReminder);
