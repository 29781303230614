import React from "react";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import InterestChips from "./interest-chips";

export const parseTags = text => {
  if (!text) {
    return [];
  }
  const words = text.split(/\s+/);
  const hashtags = words.filter(
    word => word && word[0] === "#" && word.length > 1
  );
  const cleaned = hashtags.map(t => t.replace("#", "").toLowerCase());
  // console.log("words", words, "hashtags", hashtags, "clean", "cleaned");
  return cleaned;
};

export default function NoteInput({ label }) {
  return (
    <>
      <Field
        component={TextField}
        placeholder="This are the call logs, you can even use #hashtags"
        name="notes"
        label={label}
        multiline
        rowsMax="5"
        rows="3"
        margin="normal"
        autoFocus
        fullWidth
        InputLabelProps={{
          shrink: true
        }}
        variant="outlined"
      />
      <Field name="notes">
        {({ input: { value } }) => <InterestChips tags={parseTags(value)} />}
      </Field>
    </>
  );
}
