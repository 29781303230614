import { GET_CONTACT_QUERY } from "../contact-card";
import gql from "graphql-tag";
import { useMutation } from "react-apollo-hooks";
import { useCallback } from "react";
import uniq from "lodash/uniq";

const ADD_INTEREST_MUTATION = gql`
  mutation addInterestsToContact($contactId: ID!, $interests: [String]!) {
    addInterestToContact(contactId: $contactId, interests: $interests) {
      interests
    }
  }
`;

export default function useAddInterestToContact(contactId) {
  if (!contactId) throw new Error("ContactId not defined");
  const addInterest = useMutation(ADD_INTEREST_MUTATION, {
    update: (proxy, mutationResult) => {
      const { interests } = mutationResult.data.addInterestToContact;
      const queryRes = proxy.readQuery({
        query: GET_CONTACT_QUERY,
        variables: {
          contactId
        }
      });
      proxy.writeQuery({
        query: GET_CONTACT_QUERY,
        variables: {
          contactId
        },
        data: {
          contactById: {
            ...queryRes.contactById,
            interests: uniq(interests)
          }
        }
      });
    }
  });

  const action = useCallback(
    interests =>
      addInterest({
        variables: { interests, contactId: contactId }
      }),
    [contactId]
  );
  return action;
}
