import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import useSearchQuery from "../hooks/useSearchQuery";

import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";

import { InfoSection, InfoField } from "../components/info-field";

import { Avatar } from "@material-ui/core";
import ContactDetails from "./details";

const useStyles = makeStyles(theme => ({
  header: {
    display: "flex"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: 8
  },
  content: {
    paddingTop: 0,
    flex: "1 0 auto",
    "&:last-child": {
      paddingBottom: theme.spacing.unit * 2
    }
  },
  avatar: {
    width: 190,
    height: 190,
    margin: theme.spacing.unit * 2
  },
  chip: {
    margin: theme.spacing.unit / 2
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  toggleButton: {
    padding: 12,
    borderRadius: "50%",
    minWidth: 0,
    border: 0,
    "&:hover": {
      border: 0
    }
  }
}));

/*
const InfoTooltip = () => (
  <span>
    💡 Did you know:
    <br />
    You can fast access additional information via the up arrow ⬆️
  </span>
);
*/

export function ContactHeader({
  contactId,
  firstName,
  lastName,
  role,
  image,
  companyName,
  companyURL,
  interests
}) {
  const [detailsExpanded, setDetailsExpanded] = useSearchQuery("details");
  const classes = useStyles({ detailsExpanded });
  // const toggleExpanded = useCallback(() =>
  //   setDetailsExpanded({ details: !detailsExpanded })
  // );
  const toggleExpanded = () =>
    setDetailsExpanded({ details: !detailsExpanded });
  // return <div />;
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Avatar className={classes.avatar} src={image} />
        {/* <CardMedia className={classes.avatar} image={ExampleAvatar} /> */}
        <div className={classes.details}>
          <CardHeader
            title={`${firstName} ${lastName}`}
            subheader={role}
            action={
              //@TODO: Somehow this breaks with the router. no Idea why
              // Also some fiber stuff is happening
              // <Tooltip title={<InfoTooltip />} aria-label="More Infos">
              <Button
                onClick={toggleExpanded}
                color="primary"
                variant={detailsExpanded ? "contained" : "outlined"}
                className={classes.toggleButton}
              >
                <InfoIcon />
              </Button>
              // </Tooltip>
            }
          />
          <CardContent className={classes.content}>
            <InfoSection>
              <InfoField label="Company">{companyName}</InfoField>
              <InfoField label="Homepage">
                <a href={companyURL}>{companyURL}</a>
              </InfoField>
              {interests &&
                interests.length > 0 && (
                  <InfoField label="Interests">
                    {interests.map(i => (
                      <Chip key={i} label={i} className={classes.chip} />
                    ))}
                  </InfoField>
                )}
            </InfoSection>
          </CardContent>
        </div>
      </div>
      <Collapse in={detailsExpanded}>
        <Divider />
        <ContactDetails contactId={contactId} />
      </Collapse>
    </div>
  );
}

ContactHeader.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyURL: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  interests: PropTypes.array.isRequired
};

ContactHeader.defaultProps = {
  interests: []
};

export default ContactHeader;
