import React from "react";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from "@material-ui/core";
import ActivityIcon from "../../common/activity-icon";

export default function ContactSubject({ task }) {
  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ActivityIcon type={task.type} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={task.subject} secondary={task.description} />
      </ListItem>
    </List>
  );
}
