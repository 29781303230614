import React from "react";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import MeetingIcon from "@material-ui/icons/People";
import ChatIcon from "@material-ui/icons/Chat";

export default function ActivityIcon({ type, ...rest }) {
  switch (type) {
    case "EMAIL":
      return <EmailIcon {...rest} />;
    case "PHONE":
      return <PhoneIcon {...rest} />;
    case "MEETING":
      return <MeetingIcon {...rest} />;
    case "MESSAGE":
      return <ChatIcon {...rest} />;
    default:
      console.warn("Could not find icon for activity of type", type);
      return <MeetingIcon {...rest} />;
  }
}
