import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Avatar, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

export const DialogForm = styled.div`
  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const variant = ({ variant }) => {
  switch (variant) {
    case "outlined":
      return {
        paddingTop: "16px"
      };
    case "selection":
      return {
        paddingTop: "12px"
      };
    default:
      return {
        paddingTop: "20px"
      };
  }
};

const FieldSetContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding-left: ${props => (props.inset ? "52px" : 0)};
  & + & {
    margin-top: 32px;
  }
`;
const IconContainer = styled.div`
  padding-right: 16px;
  padding-left: 12px;
  color: ${grey[500]};
  ${variant};
`;

const FieldSetContent = styled(Grid)`
  legend {
    padding-top: 16px;
  }
`;

export const SectionAvatar = styled(Avatar)`
  margin-right: 8px;
`;

const SectionTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-left: 4px;
  padding-bottom: 12px;
`;

export const SectionTitleText = styled(Typography)`
  padding-left: ${props => (props.inset ? "52px" : 0)};
`;
SectionTitleText.defaultProps = {
  variant: "h6",
  component: "span",
  inset: false
};

export const SectionTitle = ({ icon, children, inset }) => {
  return (
    <SectionTitleContainer>
      {icon && <SectionAvatar>{icon}</SectionAvatar>}
      <SectionTitleText>{children}</SectionTitleText>
    </SectionTitleContainer>
  );
};

export const FieldSet = ({ icon, children, variant, inset }) => {
  return (
    <FieldSetContainer inset={inset}>
      {icon && <IconContainer variant={variant}>{icon}</IconContainer>}
      <FieldSetContent container spacing={16}>
        {React.Children.map(children, c => {
          const xs = c.props.size || 12;
          return (
            <Grid xs={xs} item>
              {c}
            </Grid>
          );
        })}
      </FieldSetContent>
    </FieldSetContainer>
  );
};
