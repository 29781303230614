import React, { Component, Suspense } from "react";

import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MockProvider from "./apollo/mock-provider";
import AppBar from "./common/app-bar";
import { createGlobalStyle } from "styled-components";
import { SnackbarProvider } from "notistack";
import MeetingReminder from "./lead/notification/meeting-reminder";

import theme from "./theme";

import Routes from "./routes";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Spinnaker"
  }
`;

class App extends Component {
  render() {
    return (
      <>
        <MockProvider>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <SnackbarProvider maxSnack={3}>
                <Suspense fallback={<div>Loading......</div>}>
                  <AppBar />
                  <MeetingReminder />
                  <Routes />
                </Suspense>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MockProvider>
        <GlobalStyle />
      </>
    );
  }
}

export default App;
