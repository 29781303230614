const typeDefs = `

type Query {
  contacts: [Contact]
  contactById(contactId: String): Contact
}

input TaskInput {
  type: ActivityType!,
  date: String!,
  subject: String,
  description: String
}

type Mutation {
  addInterestToContact(contactId: ID, interests: [String]!): Contact
  scheduleTask(contactId: ID, task: TaskInput): Task
}

type Contact {
  uuid: ID,
  firstName: String,
  lastName: String,
  phone: String,
  email: String,
  role: String,
  image: String,
  company: Company,
  tasks: [Task],
  activities: [Activity],
  interests: [String]
  connections: Connections!
}

type Task {
  type: ActivityType,
  date: String,
  subject: String,
  description: String
}

type Activity {
  type: ActivityType
  date: String
  subject: String
}

enum ActivityType {
  ANY
  MESSAGE
  MEETING
  EMAIL
  PHONE
}

type Company {
  name: String
  url: String,
}

type Connections {
  sf_contact: SF_CONTACT 
  sf_account: SF_ACCOUNT
  sf_opportunity: SF_OPPORTUNITY
}

type SF_ACCOUNT {
  address: Address,
  description: String
  type: SF_ACCOUNT_TYPE
  tax_number: String
  opportunities: [SF_OPPORTUNITY]
}

type SF_CONTACT {
  phone: String,
  email: String
  department: String,
  reportsTo: String
}

type SF_OPPORTUNITY {
  name: String
  value: Int
  closeDate: String
  stage: SF_OPPORTUNITY_STAGE
  type: SF_OPPORTUNITY_TYPE
  probability: Int
}

enum SF_ACCOUNT_TYPE {
  Prospect
}

enum SF_OPPORTUNITY_STAGE {
  Analysis
}

enum SF_OPPORTUNITY_TYPE {
  Existing
  New_Business
}

type Address {
  street: String
  zip: String
  city: String
}

`;

export default typeDefs;
