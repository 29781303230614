import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  CardHeader,
  CardContent,
  IconButton,
  Collapse
} from "@material-ui/core";
import { useQuery } from "react-apollo-hooks";
import { GET_CONTACT_DETAILS_QUERY } from "./contact-details";
import { makeStyles } from "@material-ui/styles";
import classnames from "classnames";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import gql from "graphql-tag";

export const GET_REQUIRED_CONTACT_DETAILS_QUERY = gql`
  query contactDetailsById($contactId: ID) {
    contactById(contactId: $contactId) {
      connections {
        sf_account {
          tax_number
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 400
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

export function MissingDetails({ contactId }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  const { data, error } = useQuery(GET_CONTACT_DETAILS_QUERY, {
    variables: { contactId }
  });
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const {
    connections: { sf_account }
  } = data.contactById;

  const { tax_number } = sf_account;
  console.log("tax number", tax_number);
  if (tax_number) {
    return null;
  }

  return (
    <>
      <CardHeader
        action={
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title="Missing Information"
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <TextField
            label="Tax Number"
            defaultValue={tax_number}
            fullWidth
            size={6}
            required
          />
        </CardContent>
      </Collapse>
    </>
  );
}

export default MissingDetails;
