import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
// import IconButton from "@material-ui/core/IconButton";
import MoneyIcon from "@material-ui/icons/MoneyRounded";
// import CloudIcon from "@material-ui/icons/Cloud";
import {
  ListSubheader,
  ListItemIcon,
  // Tooltip,
  Avatar,
  ListItemAvatar
} from "@material-ui/core";

import CallToAction from "./call-to-action";

import ActivityIcon from "../../common/activity-icon";

function CurrentTask({ task }) {
  return (
    <>
      <ListSubheader>Current Focus</ListSubheader>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <ActivityIcon type={task.type} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={task.subject} />
      </ListItem>
    </>
  );
}

function Opportunities({ opportunities }) {
  if (opportunities.length === 0) {
    return null;
  }
  return (
    <>
      <ListSubheader>Opportunity</ListSubheader>
      {opportunities.map(o => (
        <ListItem>
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <ListItemText
            primary={o.name}
            secondary={` - ${o.value}, ${o.closeDate}`}
          />
          {/* <ListItemSecondaryAction>
            <Tooltip title="Open in Salesforce">
              <IconButton>
                <CloudIcon />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction> */}
        </ListItem>
      ))}
    </>
  );
}

export default function OverviewBody({ task, navigate, opportunities }) {
  return (
    <>
      <CallToAction navigate={navigate} taskType={task.type} />
      <List dense>
        {task && <CurrentTask task={task} />}
        <Opportunities opportunities={opportunities} />
      </List>
    </>
  );
}
