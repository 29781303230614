import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import NextForm from "./next-form-keyboard";
// import NextActions from "./next-actions";
import { ContactListContext } from "../contact-list-context";
import { Paper } from "@material-ui/core";
import useScheduleTask from "../actions/scheduleTask";

export function FinishSection({ enqueueSnackbar, contactId }) {
  const { navigateNext } = useContext(ContactListContext);

  const addTask = useScheduleTask(contactId);

  const callNext = async values => {
    const { channel, subject, when } = values;
    const whenString = when && when.formatted;
    const task = {
      subject: subject,
      date: when ? when.date + "" : new Date(),
      type: channel ? channel.value : "ANY"
    };
    await addTask(task);
    enqueueSnackbar(`Scheduled ${subject} for ${whenString}`);
    navigateNext();
  };
  return (
    <Paper style={{ width: 640 }}>
      <NextForm onSubmit={callNext} contactId={contactId} />
    </Paper>
  );
}

FinishSection.propTypes = {
  contactId: PropTypes.string.isRequired
};

export default withSnackbar(FinishSection);
