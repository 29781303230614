import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export function SavedSnackbar({ open }) {
  const [currentOpen, setOpen] = useState(open);
  const close = () => setOpen(false);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={currentOpen}
      onClose={() => setOpen(false)}
      autoHideDuration={3000}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={<span id="message-id">Lead was sucessfully updated</span>}
      action={[
        <Button
          key="undo"
          color="secondary"
          size="small"
          // eslint-disable-next-line no-restricted-globals
          onClick={() => history.back()}
        >
          Back
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={close}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
}

export default SavedSnackbar;
