import timeSuggest from "../../vendor/time-suggest";

export default function suggest(query, options) {
  const finalOptions = {
    future_offset: 60 * 1000,
    month_before_day: navigator.language === "en-US",
    ...options
  };
  return timeSuggest(query, finalOptions);
}
