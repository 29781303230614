/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";
import { styled } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { green, red } from "@material-ui/core/colors";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Tooltip from "@material-ui/core/Tooltip";

const DiscardButton = styled(Button)({
  backgroundColor: red[300],
  width: "30%",
  "&:hover": {
    backgroundColor: red[500]
  }
});

const RightIcon = styled(ChevronRight)(({ theme }) => {
  return {
    marginLeft: theme.spacing.unit
  };
});

const ActionButton = styled(Button)({
  backgroundColor: green[300],
  width: "70%",
  alignItems: "right",
  justifyContent: "flex-end",
  "&:hover": {
    backgroundColor: green[500]
  }
});

const Container = styled("div")({
  display: "flex"
});

const TooltipText = () => (
  <span>
    💡 Did you know:
    <br />
    You can contact straight away by pressing the right arrow ➡️
  </span>
);

export default function CallToAction({ navigate, taskType }) {
  const taskLink =
    taskType.toLowerCase() === "any" ? "phone" : taskType.toLowerCase();
  return (
    <Container>
      <DiscardButton onClick={() => navigate("discard")}>
        🐣 Chicken Out
      </DiscardButton>
      <ActionButton onClick={() => navigate(`contact/${taskLink}`)}>
        💪 Contact Now
        <Tooltip title={<TooltipText />}>
          <RightIcon />
        </Tooltip>
      </ActionButton>
    </Container>
  );
}
