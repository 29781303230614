import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { makeStyles } from "@material-ui/styles";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  CardContent,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import { PersonAdd, Close } from "@material-ui/icons";
import FinishButton from "./finish-button";
import map from "lodash/map";

const templates = {
  none: {
    name: "none",
    label: <em>Empty</em>,
    subject: "",
    text: () => ""
  },
  reconnect: {
    name: "reconnect",
    label: "Reconnect",
    subject: "Coffee next week?",
    text: name => `Hi ${name}, 

Hope you doing well. Just thought about our last conversation and I think we should meet again soon. Do you have time next week? 
    
Best,
Daniel`
  }
};

function TemplateSelect({ onChange, value }) {
  function handleChange(e) {
    onChange(e.target.value);
  }
  const [labelWidth, setLabelWidth] = useState(0);
  const inputLabelRef = React.useRef(null);

  React.useEffect(() => {
    setLabelWidth(ReactDOM.findDOMNode(inputLabelRef.current).offsetWidth);
  }, []);
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel ref={inputLabelRef} htmlFor="template">
        Template
      </InputLabel>
      <Select
        value={value}
        onChange={handleChange}
        input={
          <OutlinedInput
            labelWidth={labelWidth}
            name="template"
            id="template"
          />
        }
      >
        {map(templates, t => (
          <MenuItem key={t.name} value={t.name}>
            {t.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const GET_CONTACT_OUTREACH_QUERY = gql`
  query contactOutreachInfoById($contactId: ID) {
    contactById(contactId: $contactId) {
      firstName
      lastName
      email
    }
  }
`;

const useStyles = makeStyles(theme => ({
  adornmentInput: {
    paddingRight: 0
  },
  adornmentIcon: {
    marginRight: theme.spacing.unit / 2
  }
}));

export default function ContactEmail({ contactId }) {
  const [templateKey, setTemplateKey] = useState("none");
  const [showCC, setShowCC] = useState(false);
  const template = templates[templateKey] || templates["none"];

  const classes = useStyles();

  const { data, error } = useQuery(GET_CONTACT_OUTREACH_QUERY, {
    variables: { contactId: contactId }
  });
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const { firstName, /* lastName, */ email } = data.contactById;

  return (
    <>
      <CardContent>
        <TemplateSelect onChange={setTemplateKey} value={templateKey} />
        <TextField
          label="To"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.adornmentIcon}>
                <IconButton
                  aria-label="Add CC"
                  disabled={showCC}
                  onClick={() => setShowCC(true)}
                >
                  <PersonAdd />
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              root: classes.adornmentInput
            }
          }}
          value={email}
          variant="outlined"
        />
        {showCC && (
          <TextField
            label="CC"
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.adornmentIcon}
                >
                  <IconButton
                    aria-label="Remove CC"
                    onClick={() => setShowCC(false)}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                root: classes.adornmentInput
              }
            }}
          />
        )}

        <TextField
          label="Subject"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          value={template.subject}
        />
        <TextField
          label="Text"
          multiline
          rowsMax="8"
          rows="4"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          value={template.text(firstName)}
        />
      </CardContent>
      <FinishButton label="Send Mail" />
    </>
  );
}

ContactEmail.propTypes = {
  contactId: PropTypes.string.isRequired
};
