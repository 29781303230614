import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { makeExecutableSchema, addMockFunctionsToSchema } from "graphql-tools";
import { SchemaLink } from "apollo-link-schema";
import mocks from "./mocks";
import typeDefs from "./schema";

const schema = makeExecutableSchema({ typeDefs });

addMockFunctionsToSchema({ mocks, schema });

const link = new SchemaLink({ schema });

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

export default client;
