import React, { useState, useCallback } from "react";
import { Fab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Call } from "@material-ui/icons";
import useTimer from "react-timer-hook";
import padStart from "lodash/padStart";

const useStyles = makeStyles(theme => ({
  minutes: {
    width: 100,
    minWidth: 100,
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing.unit * 2
  }
}));

function PhoneTimer() {
  const {
    seconds,
    minutes,
    // hours,
    // days,
    startTimer,
    stopTimer,
    resetTimer
  } = useTimer({ autoStart: false });
  const [isCalling, setIsCalling] = useState(false);
  const startCall = useCallback(() => {
    setIsCalling(true);
    startTimer();
  });
  const stopCall = useCallback(() => {
    setIsCalling(false);
    stopTimer();
    resetTimer();
  });
  const classes = useStyles();
  return (
    <>
      {!isCalling && (
        <>
          <Fab
            variant="extended"
            color="primary"
            aria-label="Call"
            onClick={startCall}
          >
            <Call />
          </Fab>
          <div className={classes.minutes} />
        </>
      )}
      {isCalling && (
        <>
          <Fab
            variant="extended"
            color="secondary"
            aria-label="stop"
            onClick={stopCall}
          >
            <Call />
          </Fab>
          <Typography variant="h5" component="h3" className={classes.minutes}>
            {padStart(minutes, 2, "0")}:{padStart(seconds, 2, "0")}
          </Typography>
        </>
      )}
    </>
  );
}

export default PhoneTimer;
