import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import MeetingIcon from "@material-ui/icons/People";
import MessageIcon from "@material-ui/icons/Message";

import ContactPhone from "./contact-phone";
import ContactEmail from "./contact-email";
import ContactMeeting from "./contact-meeting";
import ContactMessage from "./contact-message";

const PHONE = "phone";
const EMAIL = "email";
const MEETING = "meeting";
const MESSAGE = "message";

const TABS = [PHONE, EMAIL, MEETING, MESSAGE];

export function ContactTabs({
  children,
  type,
  navigate,
  index,
  contactId,
  location
}) {
  return (
    <>
      <Tabs
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        value={TABS.indexOf(type)}
        onChange={(e, v) =>
          index ? navigate(TABS[v]) : navigate(`../${TABS[v]}`)
        }
      >
        <Tab icon={<PhoneIcon />} />
        <Tab icon={<EmailIcon />} />
        <Tab icon={<MeetingIcon />} />
        <Tab icon={<MessageIcon />} />
      </Tabs>
      {type === PHONE && (
        <ContactPhone navigate={navigate} contactId={contactId} />
      )}
      {type === EMAIL && <ContactEmail contactId={contactId} />}
      {type === MEETING && (
        <ContactMeeting navigate={navigate} contactId={contactId} />
      )}
      {type === MESSAGE && (
        <ContactMessage navigate={navigate} contactId={contactId} />
      )}
    </>
  );
}

export default ContactTabs;
