import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";

import { makeStyles } from "@material-ui/styles";
import Card from "@material-ui/core/Card";
import green from "@material-ui/core/colors/green";
import ContactHeader from "./contact-header";

const useStyles = makeStyles(theme => ({
  card: {
    width: 640
  },
  avatar: {
    backgroundColor: green[500]
  }
}));

export const GET_CONTACT_QUERY = gql`
  query contactById($contactId: ID) {
    contactById(contactId: $contactId) {
      uuid
      firstName
      lastName
      image
      role
      company {
        name
        url
      }
      interests
    }
  }
`;

export function SummaryCard({ children, contactId }) {
  const classes = useStyles();
  const { data, error } = useQuery(GET_CONTACT_QUERY, {
    variables: { contactId }
  });
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const {
    firstName,
    lastName,
    role,
    company,
    image,
    interests
  } = data.contactById;
  const { url: companyURL, name: companyName } = company;
  return (
    <Card className={classes.card}>
      <ContactHeader
        contactId={contactId}
        firstName={firstName}
        lastName={lastName}
        image={image}
        role={role}
        companyURL={companyURL}
        companyName={companyName}
        interests={interests}
      />
    </Card>
  );
}

export default SummaryCard;
