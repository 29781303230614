import React from "react";
import { Router, Redirect } from "@reach/router";

import ContactList from "./lead/contact-list";
import LeadPage from "./lead";

export default function Routes() {
  return (
    <Router primary={false}>
      <Redirect from="/" to="contacts" noThrow />
      <ContactList path="/contacts">
        <LeadPage path=":contactId/*" />
      </ContactList>
    </Router>
  );
}
