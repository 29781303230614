import React from "react";
import gql from "graphql-tag";
// import { SlideRight } from "../../common/slide-transition";
import { useQuery } from "react-apollo-hooks";

import { Divider, Card } from "@material-ui/core";
import head from "lodash/head";
import _get from "lodash/get";

import OverviewBody from "./overview-body";
import ContactActivity from "./contact-activity";
import SavedSnackbar from "./saved-snackbar";

export const GET_OVERVIEW_SECTION_QUERY = gql`
  query contactById($contactId: ID) {
    contactById(contactId: $contactId) {
      tasks {
        type
        date
        subject
      }
      activities {
        type
        date
        subject
      }
      connections {
        sf_account {
          type
          opportunities {
            name
            value
            closeDate
          }
        }
      }
    }
  }
`;

export const OverviewSection = React.forwardRef(
  ({ path, contactId, navigate }, ref) => {
    const previousFinished = path === "overview";
    const { data, error } = useQuery(GET_OVERVIEW_SECTION_QUERY, {
      variables: { contactId }
    });
    if (error) {
      return <div>Error: {error.message}</div>;
    }
    const { tasks, activities, connections } = data.contactById;
    const nextTask = head(tasks);
    const opportunities = _get(connections, "sf_account.opportunities", []);
    return (
      <Card style={{ width: 640 }}>
        <OverviewBody
          task={nextTask}
          navigate={navigate}
          opportunities={opportunities}
        />
        <Divider />
        <ContactActivity activities={activities} navigate={navigate} />
        <SavedSnackbar open={previousFinished} />
      </Card>
    );
  }
);

export default OverviewSection;
