import React, { useCallback } from "react";
import { Form } from "react-final-form";
import { CardContent, Button, DialogActions } from "@material-ui/core";
import styled from "styled-components";
import NoteInput, { parseTags } from "../../common/note-input";
import PhoneTimer from "./phone-timer";
import useAddInterestToContact from "../actions/addInterestToContact";
import MissingDetails from "../details/missing-details";

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

export default function ContactPhone({ navigate, contactId }) {
  const initialValues = {};
  const addInterests = useAddInterestToContact(contactId);
  const submit = useCallback(values => {
    const { notes } = values;
    const newInterests = parseTags(notes);
    addInterests(newInterests);
    navigate("../../next");
  });
  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, pristine }) => (
        <>
          <CardContent>
            <CTAContainer>
              <PhoneTimer />
            </CTAContainer>
            <NoteInput label="Discussed topics and decisions" />
          </CardContent>
          <MissingDetails contactId={contactId} />
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              // disabled={pristine}
              onClick={handleSubmit}
            >
              Define next step
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
}
