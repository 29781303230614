import React from "react";
import { navigate } from "@reach/router";
import { Button, DialogActions } from "@material-ui/core";

export default function FinishButton({ label }) {
  return (
    <DialogActions>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("../next")}
      >
        {label}
      </Button>
    </DialogActions>
  );
}

FinishButton.defaultProps = {
  label: "finish"
};
