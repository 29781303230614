import React from "react";

import { navigate } from "@reach/router";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import CardContent from "@material-ui/core/CardContent";

import { Button, CardHeader, DialogActions, Grid } from "@material-ui/core";

import AutocompleteField from "../../components/autocomplete/autocomplete-field";
import DateDropdownField from "../../components/date-dropdown/date-dropdown-field";
import createAutoFocus from "../../form/focus-next-input";

const channelOptions = [
  { value: "ANY", label: "Contact" },
  { value: "EMAIL", label: "Email" },
  { value: "PHONE", label: "Phone" },
  { value: "MEETING", label: "Meeting" }
];

const nextFocusDecorator = createAutoFocus();

function ChannelSelection() {
  return (
    <Grid container alignItems="flex-end" spacing={16}>
      <Grid item xs={9}>
        <Field
          name="channel"
          label="Next step is to"
          component={AutocompleteField}
          formControlProps={{ fullWidth: true }}
          options={channelOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => navigate("contact/email")}
          fullWidth
        >
          Email Now
        </Button>
      </Grid>
    </Grid>
  );
}

export default function WhatsNextForm({ onSubmit, children, contactId }) {
  return (
    <Form onSubmit={onSubmit} decorators={[nextFocusDecorator]}>
      {({ handleSubmit, pristine }) => (
        <>
          <CardHeader title="Next Step" />
          <CardContent>
            <Field
              autoFocus
              component={TextField}
              name="subject"
              label="Goal of next contact"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
            <ChannelSelection />

            <Field
              component={DateDropdownField}
              name="when"
              label="When"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </CardContent>
          <DialogActions style={{ padding: "8px 4px" }}>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Finish and next Person
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
}
