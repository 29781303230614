import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const reasons = ["Missing Information", "Not Interested", "Wrong Contact"];

function ReasonDialog({ onClose, selectedValue, ...other }) {
  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(value) {
    onClose(value);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="disqualify-dialog-title"
      {...other}
    >
      <DialogTitle id="disqualify-dialog-title">Select Reason</DialogTitle>
      <div>
        <List>
          {reasons.map(r => (
            <ListItem button onClick={() => handleListItemClick(r)} key={r}>
              <ListItemText primary={r} />
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
}

ReasonDialog.propTypes = {
  onClose: PropTypes.func,
  selectedValue: PropTypes.string
};

function UnqualifyButton({ onFinish, ...rest }) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
    value && onFinish && onFinish();
  };

  return (
    <>
      <Button onClick={handleClickOpen} {...rest} />
      <ReasonDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </>
  );
}

export default UnqualifyButton;
