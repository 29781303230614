import React from "react";
import { Chip } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { InfoField } from "../components/info-field";

const SingleChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing.unit / 2
}));

export default function InterestChips({ label, tags }) {
  return (
    <InfoField label={label}>
      {tags.map(t => (
        <SingleChip label={t} key={t} />
      ))}
    </InfoField>
  );
}

InterestChips.defaultProps = {
  label: "Interests"
};
