import React, { useCallback } from "react";
import { Form } from "react-final-form";
import CardContent from "@material-ui/core/CardContent";
import { Button, DialogActions } from "@material-ui/core";
import NoteInput, { parseTags } from "../../common/note-input";
import useAddInterestToContact from "../actions/addInterestToContact";
import MissingDetails from "../details/missing-details";

export default function ContactMeeting({ navigate, contactId }) {
  const initialValues = {};
  const addInterests = useAddInterestToContact(contactId);
  const submit = useCallback(values => {
    const { notes } = values;
    const newInterests = parseTags(notes);
    addInterests(newInterests);
    navigate("../../next");
  });
  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({ handleSubmit, pristine }) => (
        <>
          <CardContent>
            <NoteInput label="Discussed topics and decisions" />
          </CardContent>
          <MissingDetails contactId={contactId} />
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              // disabled={pristine}
              onClick={handleSubmit}
            >
              Define next step
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
}
