import React from "react";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import client from "./client";

export default function MockProvider({ children, customResolver }) {
  console.log(
    "MockProvider Rendered, but not taking custom resolvers into account"
  );
  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
    </ApolloProvider>
  );
}
