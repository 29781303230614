import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { Card, Divider } from "@material-ui/core";
import ContactOptions from "./contact-tabs";
import ContactSubject from "./contact-subject";

const GET_OVERVIEW_SECTION_QUERY = gql`
  query contactById($contactId: ID) {
    contactById(contactId: $contactId) {
      tasks {
        type
        date
        subject
        description
      }
    }
  }
`;

const ContactSection = React.forwardRef(
  ({ children, type, index, navigate, contactId, ...rest }, ref) => {
    const { data, error } = useQuery(GET_OVERVIEW_SECTION_QUERY, {
      variables: { contactId }
    });
    if (error) {
      return <div>Error: {error.message}</div>;
    }
    const { tasks } = data.contactById;
    const task = tasks[0];

    return (
      <Card style={{ width: 640 }}>
        <ContactSubject task={task} />
        <Divider />
        <ContactOptions
          type={type}
          navigate={navigate}
          index={index}
          contactId={contactId}
        />
      </Card>
    );
  }
);

export default ContactSection;
