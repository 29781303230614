import React, { useContext } from "react";
import { withSnackbar } from "notistack";
import NextForm from "../next/next-form";
import NextActions from "../next/next-actions";
import { ContactListContext } from "../contact-list-context";
import { Card } from "@material-ui/core";

export function DiscardCard({ enqueueSnackbar }) {
  const { navigateNext } = useContext(ContactListContext);

  const callNext = values => {
    console.log(values);
    enqueueSnackbar("Scheduled for another time");
    navigateNext();
  };
  return (
    <Card style={{ width: 640 }}>
      <NextActions canConvert={false} canEmail={false} />
      <NextForm onSubmit={callNext} />
    </Card>
  );
}

export default withSnackbar(DiscardCard);
