import React, { useState } from "react";
import useKey from "use-key-hook";
import keycode from "keycode";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import {
  Collapse,
  Typography,
  CardActions,
  IconButton
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { styled } from "@material-ui/styles";

import AddIcon from "@material-ui/icons/Add";
import ActivityIcon from "../../common/activity-icon";

import take from "lodash/take";
import slice from "lodash/slice";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import { format } from "date-fns";

const Header = styled(CardActions)({
  paddingLeft: 16,
  display: "flex",
  justifyContent: "space-between"
});

const AddIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    marginRight: -4
  }
}));

const toDate = input => (input instanceof Date ? input : new Date(input));

function ActivityList({ activities }) {
  return activities.map(a => (
    <ListItem key={a.date}>
      <Avatar>
        <ActivityIcon type={a.type} />
      </Avatar>
      <ListItemText
        primary={a.subject}
        secondary={format(toDate(a.date), "dd.MM.yy HH:mm")}
      />
    </ListItem>
  ));
}

function ContactActivity({ activities, navigate }) {
  const [expandedActivity, toggleExpandActivity] = useKeyActivityToggle();
  const sorted = reverse(sortBy(activities, "date"));
  const mostRecent = take(sorted, 2);
  const others = slice(sorted, 2);
  return (
    <>
      <Header disableActionSpacing>
        <Typography variant="h6" component="h3">
          Activity
        </Typography>
        <AddIconButton onClick={() => navigate("contact")}>
          <AddIcon />
        </AddIconButton>
      </Header>
      <List>
        <ActivityList activities={mostRecent} />
        {others &&
          others.length > 0 && (
            <>
              <ListItem button onClick={toggleExpandActivity}>
                <ListItemText primary="Show all" />
                {expandedActivity ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={expandedActivity} timeout="auto" unmountOnExit>
                <ActivityList activities={others} />
              </Collapse>
            </>
          )}
      </List>
    </>
  );
}

function useKeyActivityToggle() {
  const [expandedActivity, setExpandedActivity] = useState(false);
  const toggleExpandActivity = () => setExpandedActivity(!expandedActivity);

  useKey(
    pressedKey => {
      switch (pressedKey) {
        case keycode("s"):
          return toggleExpandActivity();
        default:
          console.log("Pressed Key not defined", pressedKey);
      }
    },
    {
      detectKeys: [keycode("s")]
    }
  );
  return [expandedActivity, toggleExpandActivity];
}

export default ContactActivity;
