import React from "react";

import { navigate } from "@reach/router";
import { Field, Form } from "react-final-form";
import { Select, Radio as RadioField, TextField } from "final-form-material-ui";
import CardContent from "@material-ui/core/CardContent";

import { DateTimePickerField } from "../../components/date-picker-field";
import createDecorator from "final-form-calculate";
import {
  MenuItem,
  Typography,
  Button,
  CardHeader,
  DialogActions,
  Grid
} from "@material-ui/core";
import { styled } from "@material-ui/styles";

import { InfoField } from "../../components/info-field";
import { addDays } from "date-fns";

function ChannelSelection() {
  return (
    <Grid container alignItems="flex-end" spacing={16}>
      <Grid item xs={9}>
        <Field
          name="channel"
          label="Next step is to"
          component={Select}
          formControlProps={{ fullWidth: true }}
        >
          <MenuItem value="ANY">Contact</MenuItem>
          <MenuItem value="EMAIL">Email</MenuItem>
          <MenuItem value="PHONE">Phone</MenuItem>
          <MenuItem value="MEETING">Meeting</MenuItem>
        </Field>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => navigate("contact/email")}
          fullWidth
        >
          Email Now
        </Button>
      </Grid>
    </Grid>
  );
}

const CheckboxContainer = styled("div")({
  display: "flex",
  alignItems: "center"
});

const InlineRadioField = styled(RadioField)({
  paddingTop: 16,
  paddingBottom: 4
});

const CheckboxLabel = styled(Typography)({
  paddingTop: 16,
  marginRight: 16
});

const decorator = createDecorator(
  // Calculations:
  {
    field: "channel", // when the value of channel changes...
    updates: {
      // ...updates when_type
      when_type: (channelValue, allValues) =>
        channelValue === "meeting" ? "when_exact" : "when_any"
    }
  }
);

function RoughTimeSelect() {
  return (
    <CheckboxContainer>
      <Field
        name="when_type"
        value="when_any"
        component={InlineRadioField}
        type="radio"
      />
      <CheckboxLabel>Roughly</CheckboxLabel>
      <Field name="when_type" type="radio" value="when_any">
        {({ input: { checked } }) => (
          <Field
            name="when_rough"
            component={Select}
            formControlProps={{ fullWidth: false }}
            disabled={!checked}
          >
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="tomorrow">Tomorrow</MenuItem>
            <MenuItem value="next_week">Next Week</MenuItem>
            <MenuItem value="two_weeks">In 2 Weeks</MenuItem>
            <MenuItem value="next_month">Next Month</MenuItem>
            <MenuItem value="next_quarter">Next Quarter</MenuItem>
          </Field>
        )}
      </Field>
    </CheckboxContainer>
  );
}
function ExactTimeSelect() {
  return (
    <CheckboxContainer>
      <Field
        name="when_type"
        value="when_exact"
        component={InlineRadioField}
        type="radio"
      />
      <CheckboxLabel>Exactly</CheckboxLabel>
      <Field name="when_type" type="radio" value="when_exact">
        {({ input: { checked } }) => (
          <Field
            component={DateTimePickerField}
            showTabs={false}
            ampm={false}
            name="when_date"
            margin="normal"
            disabled={!checked}
          />
        )}
      </Field>
    </CheckboxContainer>
  );
}

export default function WhatsNextForm({ onSubmit, children, contactId }) {
  const initialValues = {
    channel: "ANY",
    when_type: "when_any",
    when_rough: "two_weeks",
    when_date: addDays(new Date(), 14)
  };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[decorator]}
    >
      {({ handleSubmit, pristine }) => (
        <>
          <CardHeader title="Next Step" />
          <CardContent>
            <ChannelSelection />
            <InfoField label="When" style={{ marginTop: 16 }}>
              <RoughTimeSelect />
              <ExactTimeSelect />
            </InfoField>

            <Field
              component={TextField}
              name="subject"
              label="Goal of next contact"
              margin="normal"
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
            />
          </CardContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Finish and next Person
            </Button>
          </DialogActions>
        </>
      )}
    </Form>
  );
}
