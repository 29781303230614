import React from "react";

import SlideRouter from "../common/transition-container";

import Overview from "./overview";
// import Details from "./details";
import Discard from "./discard";
import Contact from "./contact";
import Next from "./next";

// https://reach.tech/router/example/embedded-routers
export default function LeadRoutes({ location, uri, contactId }) {
  return (
    <>
      <SlideRouter location={location}>
        <Overview path="/" />
        <Overview path="overview" />
        <Discard path="discard" />
        <Contact path="contact/:type" />
        <Contact path="contact" type="phone" index />
        <Next path="next" contactId={contactId} />
      </SlideRouter>
    </>
  );
}
