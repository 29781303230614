import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions
} from "@material-ui/core";

function ConvertFormDialog({ onClose, selectedValue, ...other }) {
  function handleClose() {
    onClose(selectedValue);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="disqualify-dialog-title"
      {...other}
    >
      <DialogTitle id="disqualify-dialog-title">Select Reason</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Convert this lead and create an Account, Contact and Opportunity.
        </DialogContentText>
        <TextField
          margin="dense"
          id="account_name"
          label="Account Name"
          fullWidth
        />
        <TextField
          margin="dense"
          id="contact_name"
          label="Contact Name"
          value="Daniel Long"
          fullWidth
        />
        <TextField
          margin="dense"
          id="opportunity_name"
          label="Opportunity Name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleClose} color="primary">
          Convert
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConvertFormDialog.propTypes = {
  onClose: PropTypes.func
};

function ConvertButton({ onFinish, ...rest }) {
  const [open, setOpen] = useState(false);
  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    onFinish && onFinish();
  };

  return (
    <>
      <Button onClick={handleClickOpen} {...rest} />
      <ConvertFormDialog open={open} onClose={handleClose} />
    </>
  );
}

export default ConvertButton;
