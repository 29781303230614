/**
 * Finds the input by looking if the name attribute path is existing in the errors object
 */
// const defaultFindInput: FindInput = (inputs: FocusableInput[], errors: {}) =>
//   inputs.find(input => input.name && getIn(errors, input.name));

const enterCode = "Enter";

const createDecorator = (getInputs, findInput) => form => {
  const handleEvent = event => {
    const { code, target } = event;
    if (code === enterCode) {
      const targetField = target.name;
      const fields = form.getRegisteredFields();
      const currentIndex = fields.indexOf(targetField);
      const nextIndex = currentIndex + 1;
      if (nextIndex === fields.length) {
        console.log("I should submit");
        form.submit();
      } else {
        const nextField = fields[nextIndex];
        console.log("Going to the next field", nextField);
        const nodes = document.getElementsByName(nextField);
        if (nodes.length === 1) {
          nodes[0].focus();
        }
      }
    }
  };

  window.document.addEventListener("keydown", handleEvent);
  return () => {
    window.document.removeEventListener("keydown", handleEvent);
  };
};

export default createDecorator;
