import React from "react";
import DateDropdown from "./date-dropdown";

export const DateDropdownWrapper = ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => {
  return (
    <DateDropdown {...rest} name={name} onChange={onChange} value={value} />
  );
};

export default DateDropdownWrapper;
