import React, { useState } from "react";
import { navigate } from "@reach/router";
import { Button, CardHeader, CardActions, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import UnqualifyButton from "./unqalify-button";
import ConvertButton from "./convert-lead-button";

const useStyles = makeStyles({
  button: {
    marginLeft: 4
  }
});

export default function NextActions({ canConvert, canEmail }) {
  const classes = useStyles();
  const [canConvertState, setCanConvert] = useState(canConvert);
  return (
    <>
      <CardHeader title="Quick actions" />
      <CardActions>
        {canEmail && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate("contact/email")}
            className={classes.button}
          >
            Email Now
          </Button>
        )}
        <UnqualifyButton
          variant="outlined"
          size="small"
          className={classes.button}
          onFinish={() => navigate("/contacts")}
        >
          Unqualify
        </UnqualifyButton>
        {canConvertState && (
          <ConvertButton
            variant="outlined"
            size="small"
            className={classes.button}
            onFinish={() => setCanConvert(false)}
          >
            Convert Lead
          </ConvertButton>
        )}
      </CardActions>
      <Divider />
    </>
  );
}

NextActions.defaultProps = {
  canEmail: true
};
