import React from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import SummaryCard from "./contact-card";
import LeadRoutes from "./lead-routes";

const PageContainer = styled(Grid)`
  padding-top: 16px;
`;

function ContactPage({ contactId, ...rest }) {
  return (
    <PageContainer
      container
      direction="column"
      alignItems="center"
      spacing={16}
    >
      <Grid item>
        <SummaryCard contactId={contactId} />
      </Grid>
      <Grid item>
        <LeadRoutes contactId={contactId} {...rest} />
      </Grid>
    </PageContainer>
  );
}

export default ContactPage;
