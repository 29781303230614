import React, { useEffect, useCallback } from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { ContactListContext } from "./contact-list-context";

const GET_CONTACT_LIST = gql`
  {
    contacts {
      uuid
      firstName
      lastName
    }
  }
`;

// eslint-disable-next-line no-unused-vars
function AdminList({ contacts, current }) {
  return (
    <ul>
      {contacts.map(c => (
        <li
          key={c.uuid}
          style={{ fontWeight: c.uuid === current ? "bold" : "normal" }}
        >
          {c.uuid}
        </li>
      ))}
    </ul>
  );
}

function findNext(list, current) {
  const idList = list.map(c => c.uuid);
  const currentIndex = idList.indexOf(current);
  const next = list[currentIndex + 1];
  if (next) {
    return next.uuid;
  }
  return list[0].uuid;
}

export default function ContactList({ children, navigate, ...rest }) {
  const { data, error /* loading */ } = useQuery(GET_CONTACT_LIST);
  if (error) return `Error! ${error.message}`;
  const subPath = rest["*"];
  const [contactId] = subPath.split("/");
  const { contacts } = data;
  useEffect(
    () => {
      //@TODO: if loaded, but nothing to do?
      if (!subPath && data && data.contacts && data.contacts.length > 0) {
        const next = contacts[0].uuid;
        navigate(next);
      }
    },
    [subPath, data]
  );

  const navigateNext = useCallback(() => {
    const next = findNext(contacts, contactId);
    navigate(next);
  });

  const contextValue = {
    navigateNext
  };

  return (
    <ContactListContext.Provider value={contextValue}>
      {children}
      {/* <AdminList contacts={contacts} current={contactId} /> */}
    </ContactListContext.Provider>
  );
}
