import React, { useState } from "react";
import { InfoField } from "../../components/info-field";
import { FieldSet } from "../../components/form-layout";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { CardHeader, CardContent, Avatar, Divider } from "@material-ui/core";
import { AccountBalance, Person, LocalAtm } from "@material-ui/icons";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";

export const GET_CONTACT_DETAILS_QUERY = gql`
  query contactDetailsById($contactId: ID) {
    contactById(contactId: $contactId) {
      phone
      email
      connections {
        sf_contact {
          department
          reportsTo
        }
        sf_account {
          address {
            street
            zip
            city
          }
          tax_number
          description
          type
          opportunities {
            name
            value
          }
        }
      }
    }
  }
`;

function AccountDetails(props) {
  const { address, description, type, tax_number } = props;
  const { street, zip, city } = address;
  return (
    <>
      <CardHeader
        title="Account"
        avatar={
          <Avatar>
            <AccountBalance />
          </Avatar>
        }
      />
      <CardContent>
        <FieldSet>
          <InfoField label="Street" size={12}>
            {street}
          </InfoField>
          <InfoField label="Zip" size={6}>
            {zip}
          </InfoField>
          <InfoField label="City" size={6}>
            {city}
          </InfoField>
        </FieldSet>
        <FieldSet>
          <InfoField label="Description" size={6}>
            {description}
          </InfoField>
          <InfoField label="Type" size={6}>
            {type}
          </InfoField>
        </FieldSet>
        <FieldSet>
          <TextField
            label="Tax Number"
            defaultValue={tax_number}
            fullWidth
            size={6}
            required
          />
        </FieldSet>
      </CardContent>
    </>
  );
}
AccountDetails.defaultProps = {
  address: {}
};

export function ContactDetails({ phone, email, reportsTo, department }) {
  return (
    <>
      <CardHeader
        title="Contact"
        avatar={
          <Avatar>
            <Person />
          </Avatar>
        }
      />
      <CardContent>
        <FieldSet>
          <TextField label="Phone" defaultValue={phone} size={6} fullWidth />
          <TextField label="E-Mail" defaultValue={email} fullWidth size={6} />
          <InfoField label="Department" size={6}>
            {department}
          </InfoField>
          <InfoField label="Reports to" size={6}>
            {reportsTo}
          </InfoField>
        </FieldSet>
      </CardContent>
    </>
  );
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "NOvember",
  "December"
];

function MonthSelect({ value }) {
  const [month, setMonth] = useState("January");
  function handleChange(event) {
    setMonth(event.target.value);
  }
  return (
    <Select
      value={month}
      onChange={handleChange}
      inputProps={{
        name: "month",
        id: "age-simple"
      }}
    >
      {months.map(m => (
        <MenuItem key={m} value={m}>
          {m}
        </MenuItem>
      ))}
    </Select>
  );
}

export function OpportunityDetails({ opportunities }) {
  if (opportunities.length === 0) {
    return null;
  }
  return (
    <>
      <CardHeader
        title="Opportunityies"
        avatar={
          <Avatar>
            <LocalAtm />
          </Avatar>
        }
      />
      {opportunities.map(o => (
        <CardContent key={o.name}>
          {/* <SectionTitle>{o.name}</SectionTitle> */}
          <FieldSet>
            <TextField label="Name" defaultValue={o.name} size={4} />
            <TextField label="Value" defaultValue={o.value} size={4} />
            <InfoField label="Close Date" size={4}>
              <MonthSelect value={o.closeDate} />
            </InfoField>
          </FieldSet>
          <FieldSet>
            <InfoField label="Type" size={3}>
              {o.type}
            </InfoField>
            <InfoField label="Stage" size={3}>
              {o.stage}
            </InfoField>
            {/* Addornment */}
            <InfoField label="Probability" size={3}>
              {o.probability}
            </InfoField>
          </FieldSet>
        </CardContent>
      ))}
    </>
  );
}

//@TODO: Add FormLayout with Icon Grouping
export function Details({ contactId }) {
  const { data, error } = useQuery(GET_CONTACT_DETAILS_QUERY, {
    variables: { contactId }
  });
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const {
    phone,
    email,
    connections: { sf_contact, sf_account }
  } = data.contactById;
  const fullContact = {
    ...sf_contact,
    phone,
    email
  };
  return (
    <>
      <ContactDetails {...fullContact} />
      <Divider />
      <AccountDetails {...sf_account} />
      <Divider />

      <OpportunityDetails opportunities={sf_account.opportunities} />
    </>
  );
}

export default Details;
